import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="payments--payment-method"
export default class extends Controller {
  connect() {
    if (this.element.checked) {
      this.select();
    }
  }
  select() {
    this.dispatch("selected");
  }
}
