import { Controller } from "@hotwired/stimulus";
import JSONFormatter from "json-formatter-js";

// Connects to data-controller="json-formatter"
export default class extends Controller {
  connect() {
    const json = JSON.parse(this.element.textContent);
    const formatter = new JSONFormatter(json, 3, {
      hoverPreviewEnabled: true,
      theme: "dark",
    });
    this.element.innerHTML = "";
    this.element.appendChild(formatter.render());
  }
}
