import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sort"
export default class extends Controller {
  static targets = ["dropdown"];
  connect() {
    console.log("connected");
  }

  change(event) {
    const selectedSort = event.target.value; // Get the selected value
    const url = this.dropdownTarget.dataset.url; // Base URL from data attributes

    // Get current query parameters
    const params = new URLSearchParams(window.location.search);

    // Update or remove the "sort_by" parameter
    if (selectedSort) {
      params.set("sort_by", selectedSort);
    } else {
      params.delete("sort_by");
    }

    // Reload the page with the updated parameters
    Turbo.visit(`${url}?${params.toString()}`);
  }
}
