import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="mmenu"
export default class extends Controller {
  connect() {
    console.log("connected sdhsd");
    $("nav#menu").mmenu({
      offCanvas: {
        page: {
          selector: "#page",
        },
      },
    });
    //$("nav#menu").data("mmenu").open();
  }

  open(e) {
    e.preventDefault();
    $("nav#menu").data("mmenu").open();
  }

  close(e) {
    e.preventDefault();
    $("nav#menu").data("mmenu").close();
  }

  disconnect() {}
}
