import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="json-editor"
export default class extends Controller {
  static targets = ["input"];
  connect() {
    this.element.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden"  name="${this.element.getAttribute("name")}" value='${this.element.getAttribute("value")}' data-${this.identifier}-target="input">`,
    );
  }

  disconnect() {
    this.inputTarget.remove();
  }

  update(event) {
    this.inputTarget.value = this.element.value;
    console.log(this.element.string_value);
    //console.log(this.element.json_value);
    // console.log(JSON.stringify(this.element.json_value));
  }
}
