import { Application } from "@hotwired/stimulus";

const application = Application.start();
import PasswordVisibility from "@stimulus-components/password-visibility";
import Chart from "@stimulus-components/chartjs";
import AccordionController from "@kanety/stimulus-accordion";

// Configure Stimulus development experience
window.Stimulus = application;

application.register("password-visibility", PasswordVisibility);
application.register("chart", Chart);
application.register("accordion", AccordionController);

export { application };
