import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="increment"
export default class extends Controller {
  static targets = ["input"];

  increment() {
    this.inputTarget.value = parseInt(this.inputTarget.value, 10) + 1;
    this.inputTarget.dispatchEvent(new Event("change"));
  }

  decrement() {
    if (parseInt(this.inputTarget.value, 10) <= 1) {
      return;
    }

    this.inputTarget.value = parseInt(this.inputTarget.value, 10) - 1;
    this.inputTarget.dispatchEvent(new Event("change"));
  }
}
