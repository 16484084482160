import { Controller } from '@hotwired/stimulus'
import Choices from 'choices.js'
import 'choices.js/public/assets/styles/choices.css'

// Connects to data-controller="choices"
export default class extends Controller {
  static targets = ['element']
  connect() {
    this.choices = new Choices(this.elementTarget)
  }

  disconnect() {
    this.choices.destroy()
  }
}
