import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

// Connects to data-controller="customer-account--dropdown"
export default class extends Controller {
  static targets = ["content"];
  connect() {
    useClickOutside(this);
  }

  toggle() {
    this.contentTarget.classList.toggle("hidden");
  }
}
