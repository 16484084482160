import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="poll"
export default class extends Controller {
  static values = { url: String };

  connect() {
    this.timer = setInterval(() => {
      Turbo.visit(this.urlValue);
    }, 5000);
  }

  disconnect() {
    clearInterval(this.timer);
  }
}
