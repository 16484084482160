import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dimissable"
export default class extends Controller {
  handle() {
    this.element.classList.add("animate__animated", "animate__fadeOut");
    setTimeout(() => {
      this.element.remove();
    }, 1000);
  }
}
